<template>
  <div
    class="navbar-header d-sm-flex d-none"
    style="width: 13rem;"
  >
    <b-link
      class="navbar-brand"
      to="/"
    >
      <span
        class=""
        style="display: flex; justify-content: center; font-size: x-large"
      >
        {{ appName }}
      </span>
    </b-link>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style>

</style>

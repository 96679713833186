<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto" style="align-self: center !important;">
            <span class="text-white" style="display: flex; justify-content: center; font-size: x-large">
              {{ appName }}
            </span>
          </li>
          <!-- Logo & Text -->
<!--          <li class="nav-item mr-auto">-->
<!--            <b-link-->
<!--              class="navbar-brand mt-0"-->
<!--              to="/"-->
<!--            >-->
<!--              <span class="brand-logo">-->
<!--                <b-img-->
<!--                  :src="appLogoImage"-->
<!--                  alt="logo"-->
<!--                />-->
<!--                <noscript>-->
<!--                  <strong>GAE GGUL</strong>-->
<!--                </noscript>-->
<!--              </span>-->
<!--              <h2 class="brand-text">-->
<!--                {{ appName }}-->
<!--              </h2>-->
<!--            </b-link>-->
<!--          </li>-->

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
<!--      <vertical-nav-menu-items-->
<!--        :items="navMenuItems"-->
<!--        class="navigation navigation-main"-->
<!--      />-->
      <vertical-nav-menu-items
        v-if="customNavMenuItems.length > 0"
        :items="customNavMenuItems"
        class="navigation navigation-main"
      />
      <vertical-nav-menu-items
        v-else-if="loading === false"
        :items="customNavMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

import { createNamespacedHelpers } from 'vuex'
import { LOGOUT } from '@/store/auth/action'
import { FETCH_GAME_SET_MENU } from '@/store/settings/action'

const authStore = createNamespacedHelpers('authStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      customNavMenuItems: [],
      loading: true,
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchGameSetMenu: 'fetchGameSetMenu',
    }),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...authStore.mapActions({
      logout: LOGOUT,
    }),
    ...settingsStore.mapActions({
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
    }),
    async fetchData() {
      const mySite = this.$site
      await this.$fetchGameSetMenu({
        site: mySite,
      })
      this.customNavMenuItems = navMenuItems
        .map(item => {
          const gameSetMenu = this.fetchGameSetMenu.find(menu => menu.name === item.title)
          if (gameSetMenu) {
            if (gameSetMenu.menuUseYn === 'N') {
              return null
            }
            item.title = gameSetMenu.nameCustom
          }
          return item
        })
        .filter(item => item !== null)
      // 수동으로 추가한 메뉴 항목을 customNavMenuItems에 추가
      const addNavMenuItems = [
        {
          title: '쪽지',
          route: 'messages',
          icon: 'MailIcon',
        },
        {
          title: '로그아웃',
          route: 'logout',
          icon: 'LogOutIcon',
        },
      ]
      this.customNavMenuItems = [...this.customNavMenuItems, ...addNavMenuItems]

      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Sports-Prematch',
    route: 'sports-prematch',
    icon: 'ShuffleIcon',
  },
  {
    title: 'Sports-Special',
    route: 'sports-special',
    icon: 'ShuffleIcon',
  },
  {
    title: 'Sports-Inplay',
    route: 'sports-inplay',
    icon: 'ClockIcon',
  },
  {
    title: 'Casino-Cards',
    route: 'casino-cards',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Casino-Slot',
    route: 'casino-slot',
    icon: 'ChevronsDownIcon',
  },
  {
    title: '미니게임',
    route: 'pointExchange',
    icon: 'RepeatIcon',
  },
  {
    title: '배팅내역',
    route: 'result',
    icon: 'AwardIcon',
  },
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'TagIcon',
  },
  {
    title: '공지사항',
    route: 'notice',
    icon: 'BookOpenIcon',
  },
  {
    title: '충전',
    route: 'deposit',
    icon: 'DollarSignIcon',
  },
  {
    title: '환전',
    route: 'exchange',
    icon: 'RepeatIcon',
  },
  {
    title: '포인트전환',
    route: 'pointExchange',
    icon: 'RepeatIcon',
  },
]
